import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Box = makeShortcode("Box");
const Aligner = makeShortcode("Aligner");
const Button = makeShortcode("Button");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Box sx={{
      textAlign: "center"
    }} mdxType="Box">
      <h1 {...{
        "id": "learn-about-a-better-web",
        "style": {
          "position": "relative"
        }
      }}>{`Learn About a Better Web`}<a parentName="h1" {...{
          "href": "#learn-about-a-better-web",
          "aria-label": "learn about a better web permalink",
          "className": "anchor-link after"
        }}><span parentName="a">{`¶`}</span></a></h1>
      <p><img parentName="p" {...{
          "src": "/images/kernel_process.png",
          "alt": "Learn"
        }}></img></p>
    </Box>
    <h2 {...{
      "id": "-learn-track-structure",
      "style": {
        "position": "relative"
      }
    }}>{`📖 Learn Track Structure`}<a parentName="h2" {...{
        "href": "#-learn-track-structure",
        "aria-label": " learn track structure permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Title`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Personal Inquiry`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Web 3 Enquiry`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`🌠 `}<a parentName="td" {...{
              "href": "/learn/module-0"
            }}>{`Introduction to Kernel`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`🌈 `}<a parentName="td" {...{
              "href": "/learn/module-0/play-of-pattern"
            }}>{`The Play of Pattern`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✍️ `}<a parentName="td" {...{
              "href": "/learn/module-0/trust"
            }}>{`Trust`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`🌍 `}<a parentName="td" {...{
              "href": "/learn/module-1"
            }}>{`Ethereum's History and State`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`🤯 `}<a parentName="td" {...{
              "href": "/learn/module-1/meaning"
            }}>{`Meaning`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`💯 `}<a parentName="td" {...{
              "href": "/learn/module-1/value"
            }}>{`Value`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`💰 `}<a parentName="td" {...{
              "href": "/learn/module-2"
            }}>{`A Global Financial System`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`⁉️ `}<a parentName="td" {...{
              "href": "/learn/module-2/better-questions"
            }}>{`Better Questions`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`💸 `}<a parentName="td" {...{
              "href": "/learn/module-2/money-speech"
            }}>{`Money and Speech`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`🌐 `}<a parentName="td" {...{
              "href": "/learn/module-3"
            }}>{`Take Back The Web`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`🔍 `}<a parentName="td" {...{
              "href": "/learn/module-3/intention"
            }}>{`Intention`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`🦄 `}<a parentName="td" {...{
              "href": "/learn/module-3/freedom"
            }}>{`Freedom`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ℹ️ `}<a parentName="td" {...{
              "href": "/learn/module-4"
            }}>{`Internet Age Institutions`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`👑 `}<a parentName="td" {...{
              "href": "/learn/module-4/governance"
            }}>{`Govern Yourself`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`➕ `}<a parentName="td" {...{
              "href": "/learn/module-4/liberal-radical"
            }}>{`Liberally Radical`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`📈 `}<a parentName="td" {...{
              "href": "/learn/module-5"
            }}>{`Tokens and Mechanism Design`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`👂 `}<a parentName="td" {...{
              "href": "/learn/module-5/listening-stories"
            }}>{`Listening and Stories`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`👨‍👩‍👧‍👦 `}<a parentName="td" {...{
              "href": "/learn/module-5/incentives"
            }}>{`Incentives`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`⚖️ `}<a parentName="td" {...{
              "href": "/learn/module-6"
            }}>{`Scaling Principled Games`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`🎓 `}<a parentName="td" {...{
              "href": "/learn/module-6/learn"
            }}>{`Learn How To Learn`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`👮 `}<a parentName="td" {...{
              "href": "/learn/module-6/censorship-resistance"
            }}>{`Censorship Resistance`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`🎁 `}<a parentName="td" {...{
              "href": "/learn/module-7"
            }}>{`The Gift`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`💝 `}<a parentName="td" {...{
              "href": "/learn/module-7/giving"
            }}>{`Giving`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`🏊 `}<a parentName="td" {...{
              "href": "/learn/module-7/scale-ability"
            }}>{`Scale-ability`}</a></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "internal-setup",
      "style": {
        "position": "relative"
      }
    }}>{`Internal Setup`}<a parentName="h3" {...{
        "href": "#internal-setup",
        "aria-label": "internal setup permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Crafted Reading`}</strong>{` is made up of 2 separate pieces: a "personal" series of enquiries designed to teach you one of the principles we believe are important for your own navigation of this new and wild web; and a series of "web 3" enquiries which is more technical and goes to the heart of critical concepts that we often don't reflect upon enough, like "Trust", "Value" and "Freedom".`}</li>
      <li parentName="ol">{`The `}<strong parentName="li">{`Crafted Reading`}</strong>{` is specifically not long and hopefully not boring.
It should take you around 5 minutes to read each piece, so we only expect 10 minutes of reading in order to begin discussing each module. The ideas each piece presents are not "the truth" - they are intended to get you thinking so that you can come to your own unique conclusions about a wide range of critical topics. If you would prefer to listen to each piece instead - along with an extended discussion of it - just add "#listen" to the end of the url. For instance, `}<a parentName="li" {...{
          "href": "https://www.kernel.community/en/learn/module-5/listening-stories#listen"
        }}>{`https://www.kernel.community/en/learn/module-5/listening-stories#listen`}</a>{` will take you to the audio for that crafted reading. `}</li>
      <li parentName="ol"><strong parentName="li">{`Curated Material`}</strong>{` is specifically `}<strong parentName="li">{`not`}</strong>{` a long list of links no-one will ever have the time truly to reflect upon.`}</li>
      <li parentName="ol">{`We have picked 3-4 pieces for each week, in different formats (video, text, audio), and we have written detailed briefs for each piece. This is because we don't want to flood you with information; we want to teach you how to find the best information and then use it to transform your understanding and develop the skills required to work with decentralized systems and tools. We recommend that you familiarise yourself with each brief before the conversation starts.`}</li>
    </ol>
    <p>{`As you'll see in our first module, conversations are critical to Kernel and the framework for learning which we're presenting here. Throughout the course of each module, we will also have a number of smaller conversations on a variety of themes. These conversations are hosted between you and other Kernel fellows, because we learn best from our own peers.`}</p>
    <Aligner center mdxType="Aligner">
    <Button to='/learn/module-0/' mdxType="Button">Begin!</Button>
    </Aligner>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      